const hostNameAPI: string = 'https://doit-demo.vnptit.vn/dmit/api';
const hostNameOrigin: string = new URL(hostNameAPI).origin;
const arcgisServerHost: string = 'https://vnptbinhduong.vn:6444';
export const environment = {
  production: true,
  WEBSITE_TITLE: 'Quản lý Cơ sở dữ liệu ngành Công Thương tỉnh ...',
  PROVINCE_CODE: '74',
  PROVINCE_NAME: 'Bình Dương',
  INFORMATION: {
    INFO_NAME: 'Sở công thương tỉnh ...',
    INFO_FOOTER_TITLE: 'Quản lý Cơ sở dữ liệu ngành Công Thương',
    INFO_ADDRESS: 'Trung tâm Hành chính tỉnh ...',
    INFO_EMAIL: 'soct@dev.gov.vn',
    INFO_PHONE: '(+84) 012 3456789',
    INFO_LOGO: '/assets/images/logo/logo-mini.png',
    INFO_LOGO_TRANSPARENT: '/assets/images/logo/logo.png',
    INFO_LOGO_ALT: 'S%E1%BB%9E%20C%C3%94NG%20TH%C6%AF%C6%A0NG%3Cbr%20%2F%3ET%E1%BB%88NH%20S%C3%93C%20TR%C4%82NG'
  },
  HOST_NAME_API: hostNameAPI,
  HOST_NAME_ORIGIN: hostNameOrigin,
  BASE_HREF: '/dmit',
  SYSTEM_API_URL: hostNameAPI + '/doit-api-system/v1/',
  MAIN_API_URL: hostNameAPI + '/doit-api-main/v1/',
  CAT_API_URL: hostNameAPI + '/doit-api-cat/v1/',
  ITD_API_URL: hostNameAPI + '/doit-api-itd/v1/',
  ITP_API_URL: hostNameAPI + '/doit-api-itp/v1/',
  PB_API_URL: hostNameAPI + '/doit-api-pb/v1/',
  DYNAMIC_API_URL: hostNameAPI + '/doit-api-dynamic/v1/',
  SURVEY_API_URL: hostNameAPI + '/doit-api-fieldsurveytool/v1/',
  NOTIFICATION_API_URL: hostNameAPI + '/doit-api-notification/v1/',
  API_LGSP: 'https://api.binhduong.gov.vn:8687/',
  REPORT_API_URL: hostNameAPI + '/doit-api-report/v1/',
  UPLOAD_FILE_API_URL: hostNameAPI + '/doit-api-system/v1/File/UploadNew/',
  DOWNLOAD_FILE_API_URL: hostNameAPI + '/doit-api-system/v1/File/DownloadNew/',
  ARCGIS_SERVER_URL: arcgisServerHost + '/arcgis/rest/services',
  ARCGIS_SXD_URL: 'http://gisxd.binhduong.gov.vn:81',
  MQTT: {
    SERVER: '113.161.160.168',
    PROTOCOL: 'ws',
    PORT: 8083,
  },
  TRANSLATE_JSON_TEXT: '',
  HOME_PAGE: '',
  ENT_HOME_PAGE: '',
  VERSION: 'v1.0.26-prod',
  BROWSER_INFO:
    'Vui lòng sử dụng trình duyệt Google Chrome hoặc Microsoft Edge để có trải nghiệm tốt nhất',
  PROJCS: {
    NAME: 'BINHDUONG_VN2000',
    CENTRAL_MERIDIAN: 105.75,
  },
  DOWNLOAD_APP: {
    ANDROID_URL: '',
    IOS_URL: '',
  },
  BACKUP_NAME: 'DOIT_BD',
  GEOSERVER_WORK_SPACE_NAME: 'DOIT_DEMO',
  GEOSERVER_URL: hostNameOrigin + '/geoserver/',
  GEOSERVER_WMTS_CAPABILITIES:
    hostNameOrigin +
    '/geoserver/' +
    '/gwc/service/wmts?service=WMTS&version=1.1.1&request=GetCapabilities',
  MAPPROXY_URL: hostNameOrigin + '/mapproxy/',
  MAPPROXY_WMTS_CAPABILITIES:
    hostNameOrigin + '/mapproxy/' + '/wmts/1.0.0/WMTSCapabilities.xml',
  ARCGIS_WMTS_CAPABILITIES: '',
  SRID: 'EPSG:8439',
  CENTER_COORDINATES: '106.7%2C11.15',
  DEFAULT_ZOOM: 10,
  MIN_ZOOM: 10,
  MAX_ZOOM: 19,
  MIN_RESOLUTION: 25,
  MAX_RESOLUTION: 25,
  DEFAULT_ZOOM_RESOLUTION: 2,
  CUSTOM_GRID: 'custom_grid',
  DISTRICT_LAYER: 'PC_Districts',
  WARD_LAYER: 'DOIT_WARD',
  ENVIRONMENT_TYPE: 'demo',
  LOGIN_SSO_URL: '',
  LOGOUT_SSO_URL: '',
  WKT: `PROJCS%5B%22EPSG%3A8439%22%2C%20%0A%20%20%20%20%20%20GEOGCS%5B%22EPSG%3A8439%22%2C%20%0A%20%20%20%20%20%20%20%20DATUM%5B%22Vietnam%202000%22%2C%20%0A%20%20%20%20%20%20%20%20%20%20SPHEROID%5B%22WGS%2084%22%2C%206378137.0%2C%20298.257223563%2C%20AUTHORITY%5B%22EPSG%22%2C%227030%22%5D%5D%2C%20%0A%20%20%20%20%20%20%20%20%20%20TOWGS84%5B-191.90441429%2C%20-39.30318279%2C%20-111.45032835%2C%200.00928836%2C%200.01975479%2C%20-0.00427372%2C%200.252906278%5D%2C%20%0A%20%20%20%20%20%20%20%20%20%20AUTHORITY%5B%22EPSG%22%2C%226756%22%5D%0A%20%20%20%20%20%20%20%20%5D%2C%20%0A%20%20%20%20%20%20%20%20PRIMEM%5B%22Greenwich%22%2C%200.0%2C%20AUTHORITY%5B%22EPSG%22%2C%228901%22%5D%5D%2C%20%0A%20%20%20%20%20%20%20%20UNIT%5B%22degree%22%2C%200.017453292519943295%5D%2C%20%0A%20%20%20%20%20%20%20%20AXIS%5B%22Geodetic%20longitude%22%2C%20EAST%5D%2C%20%0A%20%20%20%20%20%20%20%20AXIS%5B%22Geodetic%20latitude%22%2C%20NORTH%5D%2C%20%0A%20%20%20%20%20%20%20%20AUTHORITY%5B%22EPSG%22%2C%224756%22%5D%0A%20%20%20%20%20%20%5D%2C%20%0A%20%20%20%20%20%20PROJECTION%5B%22Transverse_Mercator%22%5D%2C%20%0A%20%20%20%20%20%20PARAMETER%5B%22central_meridian%22%2C%20105.5%5D%2C%20%0A%20%20%20%20%20%20PARAMETER%5B%22latitude_of_origin%22%2C%200.0%5D%2C%20%0A%20%20%20%20%20%20PARAMETER%5B%22scale_factor%22%2C%200.9999%5D%2C%20%0A%20%20%20%20%20%20PARAMETER%5B%22false_easting%22%2C%20500000.0%5D%2C%20%0A%20%20%20%20%20%20PARAMETER%5B%22false_northing%22%2C%200.0%5D%2C%20%0A%20%20%20%20%20%20UNIT%5B%22m%22%2C%201.0%5D%2C%20%0A%20%20%20%20%20%20AXIS%5B%22x%22%2C%20EAST%5D%2C%20%0A%20%20%20%20%20%20AXIS%5B%22y%22%2C%20NORTH%5D%2C%20%0A%20%20%20%20%20%20AUTHORITY%5B%22EPSG%22%2C%228439%22%5D%0A%20%20%20%20%5D`,
};
